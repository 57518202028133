import { FunctionComponent, useEffect, useRef, useState } from "react";

import {
  Alternative,
  Button,
  Card,
  Form,
  LysaFormRef,
  SNACKBAR_TYPES,
  Select,
  Snackbar,
  Typography,
} from "@lysaab/ui-2";
import { useHistory } from "react-router";
import {
  DEFAULT_PAYOUT_AGE,
  DEFAULT_WITHDRAWAL_MONTHS,
  useTransfer,
} from "../TransferContext";
import {
  GlidepathDataPoint,
  dataInvestments,
} from "../../../../../data/dataInvestments";
import { getUserAge } from "../utils/userAge";
import { LysaCountry } from "@lysaab/shared";
import { defineMessages, useIntl } from "react-intl";
import { useUser } from "../../../../../context/UserContext";
import { LegalEntityType } from "../../../../../data/dataLogin";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { TRANSFER_PENSIONS_URL } from "../TransferPensionsStory";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { GlidepathGraph } from "../components/glidepathGraph/GlidepathGraph";

import "./Advice.scss";

const messages = defineMessages({
  customPayoutAgePreference: {
    id: "sweden.transfer-pension.advice.payout-age.alternative",
  },
  customPayoutTimePreference: {
    id: "sweden.transfer-pension.advice.payout-time.alternative",
  },
  customPayoutAgeDefaultPreference: {
    id: "sweden.transfer-pension.advice.payout-age.default-alternative",
  },
  customPayoutTimeDefaultPreference: {
    id: "sweden.transfer-pension.advice.payout-time.default-alternative",
  },
});

interface Props {
  next: () => void;
  toRiskDeviation: () => void;
  toFees: () => void;
}

interface GraphData {
  glidePath: GlidepathDataPoint[];
  age: number;
  withdrawalAge: number;
}

const PAYOUT_INTERVAL_START = 5;
const PAYOUT_INTERVAL_END = 20;

export const Advice: FunctionComponent<Props> = ({
  next,
  toRiskDeviation,
  toFees,
}) => {
  const intl = useIntl();
  const [transfer, setTransfer] = useTransfer();
  const formRef = useRef<LysaFormRef>();
  const user = useUser();
  const age = getUserAge(user.tin);
  const [glidepath, setGlidepath] = useState<GraphData>({
    age: age ?? 0,
    withdrawalAge: transfer.withdrawalAge,
    glidePath: [],
  });
  const history = useHistory();

  /**
   * Safe guard in case we loose context. On refresh etc. Send user back to start of story.
   */
  useEffect(() => {
    if (typeof transfer?.moves[0]?.institute === "undefined") {
      history.replace(getNavLink(TRANSFER_PENSIONS_URL));
    }
  }, [history, transfer?.moves]);

  useEffect(() => {
    if (
      !age ||
      user.legalEntityType !== LegalEntityType.PERSON ||
      user.country !== LysaCountry.SWEDEN
    ) {
      return;
    }

    dataInvestments
      .getPensionGlidepath(
        age,
        transfer.withdrawalAge,
        transfer.withdrawalMonths,
        transfer.takenRiskDeviation
      )
      .then((response) => {
        /**
         * For smoother lines we only need first, rebalance start, rebalance end and last.
         */
        const firstTakenRisk = response.glidePath[0].takenRisk;
        const indexForRebalanceStart = response.glidePath.reduceRight(
          (foundIndex, element, index) => {
            if (foundIndex === -1 && element.takenRisk === firstTakenRisk) {
              return index;
            }
            return foundIndex;
          },
          -1
        );

        const first = response.glidePath[0];
        const rebalanceStart =
          response.glidePath[Math.max(indexForRebalanceStart, 0)];
        const rebalanceEnd = response.glidePath[response.glidePath.length - 2];
        const last = response.glidePath[response.glidePath.length - 1];

        const smoothGlidepath = [first, rebalanceStart, rebalanceEnd, last];

        setGlidepath({
          glidePath: smoothGlidepath,
          age,
          withdrawalAge: transfer.withdrawalAge,
        });
      });
  }, [
    age,
    transfer.withdrawalAge,
    transfer.withdrawalMonths,
    transfer.takenRiskDeviation,
    user.country,
    user.legalEntityType,
    user.tin,
  ]);

  if (!age) {
    return null;
  }

  const payoutAgeAlternatives = generateYearArray(Math.max(age, 55), 80).map(
    (age): Alternative<number> => ({
      text: intl.formatMessage(
        age === DEFAULT_PAYOUT_AGE
          ? messages.customPayoutAgeDefaultPreference
          : messages.customPayoutAgePreference,
        {
          age: age,
        }
      ),
      value: age,
    })
  );

  const timeAlternatives = generateYearArray(
    PAYOUT_INTERVAL_START,
    PAYOUT_INTERVAL_END
  ).map(
    (year): Alternative<number> => ({
      text: intl.formatMessage(
        year === DEFAULT_WITHDRAWAL_MONTHS / 12
          ? messages.customPayoutTimeDefaultPreference
          : messages.customPayoutTimePreference,
        {
          years: year,
        }
      ),
      value: year,
    })
  );

  const stocksStart = Math.min(100 + transfer.takenRiskDeviation, 100);
  const stocksEnd = 20 + transfer.takenRiskDeviation;

  const rebalanceDefault = 10;
  let rebalanceStart = rebalanceDefault;

  // Wait for glidepath to initialised in state
  if (glidepath.glidePath.length > 0) {
    const rebalanceStartIndex =
      glidepath.glidePath.findIndex((datapoint) => datapoint.takenRisk < 100) -
      1;
    // Check if a valid index was found
    if (rebalanceStartIndex !== -1) {
      const rebalanceStartAge =
        glidepath.glidePath[rebalanceStartIndex].age + 1;
      rebalanceStart = Math.abs(
        Math.min(rebalanceDefault, transfer.withdrawalAge - rebalanceStartAge)
      );
    }
  }

  return (
    <article className="transfer-pension-story-advice">
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid) {
            return;
          }
          next();
        }}
      >
        <GlidepathGraph
          glidepath={glidepath.glidePath}
          age={glidepath.age}
          withdrawalAge={glidepath.withdrawalAge}
        />
        <Typography type="h2">
          <TranslatedText id="sweden.transfer-pension.advice.header" />
        </Typography>
        <Typography type="body">
          <TranslatedText id="sweden.transfer-pension.advice.intro" />
        </Typography>
        {age <= DEFAULT_PAYOUT_AGE && (
          <p>
            <TranslatedText
              id="sweden.transfer-pension.advice.advice"
              values={{
                stocksStart: () => {
                  return <span>{stocksStart}</span>;
                },
                rebalanceStart: () => {
                  return <span>{rebalanceStart}</span>;
                },
                stocksEnd: () => {
                  return <span>{stocksEnd}</span>;
                },
              }}
            />
          </p>
        )}
        <Button
          className="edit-allocation"
          block
          variant="outlined"
          type="button"
          icon="Edit"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.advice.edit-allocation",
          })}
          onClick={toRiskDeviation}
        />
        <section className="payout-section">
          <Typography type="h3">
            <TranslatedText
              id={"sweden.transfer-pension.advice.payout-plan.header"}
            />
          </Typography>
          <Card className="payout-card">
            <Select
              alternatives={payoutAgeAlternatives}
              label={intl.formatMessage({
                id: "sweden.transfer-pension.advice.payout-age.select.label",
              })}
              placeholder={intl.formatMessage({
                id: "sweden.transfer-pension.advice.payout-age.select.placeholder",
              })}
              value={payoutAgeAlternatives.find(
                (alternative) => alternative.value === transfer.withdrawalAge
              )}
              onChange={(newValue) =>
                setTransfer({ withdrawalAge: newValue.value })
              }
            />
            <Select
              alternatives={timeAlternatives}
              label={intl.formatMessage({
                id: "sweden.transfer-pension.advice.payout-time.select.label",
              })}
              placeholder={intl.formatMessage({
                id: "sweden.transfer-pension.advice.payout-time.select.placeholder",
              })}
              value={timeAlternatives.find(
                (alternative) =>
                  alternative.value === transfer.withdrawalMonths / 12
              )}
              onChange={(newValue) =>
                setTransfer({ withdrawalMonths: newValue.value * 12 })
              }
            />
          </Card>
        </section>
        <Snackbar type={SNACKBAR_TYPES.INFO} icon>
          <div style={{ textAlign: "left" }}>
            <TranslatedText id="sweden.transfer-pension.advice.snackbar" />
          </div>
        </Snackbar>
        <section className="action-buttons">
          <Button
            block
            type="button"
            label={intl.formatMessage({
              id: "sweden.transfer-pension.advice.next",
            })}
            onClick={next}
          />
          <Button
            block
            variant="secondary"
            type="button"
            label={intl.formatMessage({
              id: "sweden.transfer-pension.advice.show-details",
            })}
            onClick={toFees}
          />
        </section>
      </Form>
    </article>
  );
};

function generateYearArray(start: number, end: number) {
  const years = [];
  for (let i = start; i <= end; i++) {
    years.push(i);
  }
  return years;
}
